import React from 'react';
import { GenericItem } from '@marty-js/api-sdk/types';
import { PostItem } from '@marty-js/design/src/molecules/post-item';
import { PostItemShowcase } from '@marty-js/design/src/molecules/post-item-showcase';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';
import styled, { css } from 'styled-components';
import { postItemReducer } from '../../../../utils/listing';

export type ShowcaseListingProps = {
  items: GenericItem[];
};

const ShowcaseListingWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: var(--spacer-m-to-s);

  > * {
    flex: 1;
  }

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      margin: 0 0 var(--spacer);
    `,
  )}
`;

const ShowcaseBottom = styled.div`
  display: flex;
  justify-content: space-between;
  gap: var(--spacer-m-to-s);
  width: 100%;

  > * {
    flex: 1;
  }

  ${mq.lte(
    DEVICE_SIZE.MEDIUM,
    css`
      flex-direction: column;
    `,
  )}
`;

export const ShowcaseListing = ({ items }: ShowcaseListingProps) => {
  const itemsIsArray = Array.isArray(items);
  const featuredItem = itemsIsArray ? items[0] : null;

  return (
    <ShowcaseListingWrapper>
      {featuredItem && <PostItemShowcase {...postItemReducer({ item: featuredItem })} />}
      <ShowcaseBottom>
        {itemsIsArray
          ? items
              ?.slice(1, 3)
              .map((item: GenericItem, index: number) => <PostItem key={index} {...postItemReducer({ item })} card />)
          : null}
      </ShowcaseBottom>
    </ShowcaseListingWrapper>
  );
};
