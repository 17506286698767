import React, { useEffect, useMemo, useRef, useState } from 'react';
import { GenericItem } from '@marty-js/api-sdk/types';
import { GetGenericItemBySectionVariables } from '@marty-js/api-sdk/__generated__/GetGenericItemBySection';
import { useGetGenericItemBySection } from '@marty-js/api-sdk/services/genericItemBySection';
import { TranslationType, useTranslation } from '@marty-js/design/src/utils/translation';
import { SectionNavCloud } from '@marty-js/design/src/molecules/section-nav-cloud';
import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';
import { Cta } from '@marty-js/design/src/molecules/cta';

import { HeadingOne } from '@marty-js/design/src/atoms/h1';
import { HeadingTwo } from '@marty-js/design/src/atoms/h2';

import ArrowPlainRight from '@marty-js/design/src/icons/arrowPlainRight';
import DiscoveryItem from '@marty-js/design/src/molecules/discovery-item';
import SwitchViewMode from '@marty-js/design/src/molecules/switch-view-mode';
import { PostItemMix } from '@marty-js/design/src/molecules/post-item-mix';
import type {
  InsertTabs,
  LayoutSectionProps,
  PluginArticleProcessorLight,
  PluginGenericItemByQueryConfig,
  PluginSectionInserts,
  PluginSectionList,
  PluginSectionSelection,
  SectionInsert,
} from '../types';

import { Cell } from '../../../article-processor/types';
import { useMetaSeoContext, useSetMetaTitleIfNull, useSetPageNumber } from '../../../utils/metaSeoContext';
import editorSlateToText from '../../../utils/editorSlateToText';
import { Container, FullContent, MainContent, SideContent } from '../../../atoms/grid-container';
import { Ad } from '../../../ads/ad';
import { Breadcrumb } from '../../../atoms/breadcrumb';
import { SlateContentPlugin } from '../../../article-processor/plugins/slate.content';
import { PostsList } from '../../../layout/post/posts-list';
import { ShowcaseListing } from './partials/showcase-listing';
import { ShowcaseAndCompactListing } from './partials/showcase-and-compact-listing';
import { ShowcaseListingAd } from './partials/showcase-listing-ad';
import { postItemReducer } from '../../../utils/listing';
import { TopBrands } from './partials/top-brands';
import Default from './default';
import { ShowcaseOneItem } from './partials/showcase-one-item';
import { useAddServerSideZones } from '../../../ads/AdContext';
import { SelectedItemsLineTabs } from './partials/selected-items-line-tabs';
import { getHistoryDate } from '../../../atoms/date/utils';

export type MainListingSectionData = {
  mainFlux: PluginGenericItemByQueryConfig;
  textJson: PluginArticleProcessorLight;
  inserts?: PluginSectionInserts;
  navigation?: PluginSectionList;
  sectionSelection?: PluginSectionSelection;
  topDownload: any;
  discovery?: any;
  tabs: InsertTabs;
};

const Hat = styled.div`
  display: flex;
  justify-content: space-between;

  ${mq.lte(
    DEVICE_SIZE.MEDIUM,
    css`
      flex-direction: column;
      justify-content: flex-start;
    `,
  )}
`;

const DiscoveryContainer = styled.div`
  box-sizing: border-box;
  max-width: 100%;

  > * {
    border: solid 1px #ddd;
    border-radius: 6px;
    margin: 0;
    padding: var(--spacer-s);
  }

  ${mq.gte(
    DEVICE_SIZE.MEDIUM,
    css`
      max-width: 310px;
      margin: 0 0 0 auto;
    `,
  )}
`;

const Introduction = styled.div`
  ${mq.lte(
    DEVICE_SIZE.MEDIUM,
    css`
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      display: -webkit-box;
      margin: 3px 0;
      overflow: hidden;

      &.mod-collapsed {
        display: initial;
      }
    `,
  )}

  ${mq.gte(
    DEVICE_SIZE.MEDIUM,
    css`
      max-width: 50%;
    `,
  )}

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      max-width: 800px;
    `,
  )}
`;

const ReadMore = styled.div`
  cursor: pointer;
  display: inline-block;
  text-decoration: underline;
  text-underline-offset: 3px;

  ${mq.gte(
    DEVICE_SIZE.MEDIUM,
    css`
      display: none;
    `,
  )}
`;

const Featured = styled(Container)`
  margin: var(--spacer-s) 0 var(--spacer-m-fluid);
`;

const AdFullContent = styled(FullContent)`
  margin: 0;
`;

const TitleFeed = styled.div`
  align-items: center;
  display: flex;
  gap: var(--spacer-s);
  justify-content: space-between;

  ${mq.lte(
    DEVICE_SIZE.MEDIUM,
    css`
      margin: 0 0 var(--spacer-s);

      h2 {
        margin: 0 !important;
      }
    `,
  )};
`;

const getInsertTitle = (insert: SectionInsert, t: TranslationType): string => {
  if (!insert?.customTitle) {
    return insert.title;
  }

  if (insert.customTitle === 'lab') {
    return t('sdk.template.main_listing.insert.lab');
  }

  if (insert.customTitle === 'best-pick-label') {
    return t('sdk.template.main_listing.insert.bestPick', { title: insert.title });
  }

  return insert.title;
};

const formatInsertElements = (data: PluginSectionInserts, t: TranslationType): any[] => {
  const insertElements: any[] = [];

  if (!data?.inserts || !data?.inserts.length) {
    return insertElements;
  }

  data.inserts
    .filter((insert) => {
      if (!insert?.itemList?.length) {
        return null;
      }

      return insert;
    })
    .filter(Boolean)
    .forEach((insert, index): void => {
      const insertTitle = getInsertTitle(insert, t);
      if (index === 0) {
        insertElements.push(
          <ShowcaseListingAd
            items={insert.itemList}
            title={insertTitle}
            cta={{ link: insert.link, label: insert?.linkLabel || insertTitle }}
          />,
        );
      } else {
        if (index === 2) {
          insertElements.push(<Ad className="Billboard_2" desktopOnly noBackground />);
        }

        const cta = {
          link: insert.link,
          label: `${insertTitle}`,
        };

        if (insert.itemList.length > 1) {
          insertElements.push(
            <ShowcaseAndCompactListing
              items={insert.itemList}
              title={insertTitle}
              cta={cta}
              spaceBottom
              spaceTop={index === 2}
            />,
          );
        } else {
          insertElements.push(
            <ShowcaseOneItem item={insert.itemList[0]} title={insertTitle} spaceBottom spaceTop={index === 2} />,
          );
        }
      }
    });

  return insertElements;
};

const MainListingComp: React.FC<LayoutSectionProps<MainListingSectionData>> = ({
  section,
  data,
  breadcrumb,
  pageInfo,
}) => {
  const t = useTranslation();
  const [genericItems, setGenericItems] = useState<GenericItem[]>(data.mainFlux?.genericItemList);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [mode, setMode] = useState<string>(null);
  const size = data.mainFlux?.info?.size ?? 10;
  const sectionMainFluxSortedBy = data?.mainFlux?.info?.sortBy;
  const introductionRef = useRef<HTMLParagraphElement>(null);
  const variables: GetGenericItemBySectionVariables = useMemo(
    () => ({
      sectionId: pageInfo?.section?.id,
      from: 0,
      size,
    }),
    [pageInfo?.section?.id, size],
  );

  const { result, loading } = useGetGenericItemBySection({ variables });

  useEffect(() => {
    if (!loading) {
      if (result) {
        setGenericItems([...data.mainFlux.genericItemList, ...result.genericItemBySection.genericItems]);
      }
    }
  }, [setGenericItems, result, loading, data.mainFlux, pageInfo.section.url]);
  const hatContent: Cell = data?.textJson?.articleJson;

  const navigation = data?.navigation?.sectionList || null;
  const sectionSelection = data?.sectionSelection?.data || null;

  const insertElements = formatInsertElements(data.inserts, t);

  useSetMetaTitleIfNull(t('sdk.template.simple_listing.metaTitle', { title: section.title }));
  useSetPageNumber(pageInfo.pageNumber);

  const metaSeoContext = useMetaSeoContext();

  if (!metaSeoContext.description && hatContent) {
    metaSeoContext.description = editorSlateToText(hatContent).replace(/(^.{180}.+?)\..+/g, '$1...');
  }

  if (!metaSeoContext.description) {
    metaSeoContext.description = t('sdk.template.main_listing.metaDescription', { title: section.title });
  }

  const { zoneSlug, keywords } = data?.discovery?.data?.data || { zoneSlug: null, keywords: null };
  const zoneDiscovery = useMemo(() => ({ zoneSlug, keywords }), [keywords, zoneSlug]);
  const dataDiscoveryOfTheMoment = useAddServerSideZones(zoneDiscovery);
  const discoveryOfTheMoment = useMemo(() => {
    if (dataDiscoveryOfTheMoment?.component?.data) {
      const componentData = dataDiscoveryOfTheMoment.component.data;
      dataDiscoveryOfTheMoment.impressionCount();

      if (!componentData?.data?.landingPage) {
        return null;
      }

      return {
        name: componentData.data?.name,
        landingPage: componentData.data?.landingPage,
        id: componentData.data?.download?.id,
        title: componentData.data?.download?.title,
        image: componentData.data?.download?.image,
        description: componentData.data?.download?.description,
        url: componentData.data?.download?.url,
        updatedAt: componentData.data?.download?.updatedAt,
      };
    }

    return null;
  }, [dataDiscoveryOfTheMoment]);

  const brands = useMemo(() => {
    if (sectionSelection?.sections?.sectionList?.length) {
      return sectionSelection.sections.sectionList.slice(0, 6).map((currentSection) => {
        return {
          title: currentSection.title,
          url: currentSection.url,
        };
      });
    }

    return null;
  }, [sectionSelection]);

  const insertTab: any = data.tabs;

  return (
    <>
      <Container>
        <FullContent>
          <Breadcrumb breadcrumb={breadcrumb} />
          <HeadingOne>{section.title}</HeadingOne>
          <Hat>
            {hatContent ? (
              <div>
                <Introduction
                  className={isCollapsed ? 'mod-collapsed' : ''}
                  ref={introductionRef}
                  data-testid="hat-content"
                >
                  <SlateContentPlugin cell={hatContent} />
                </Introduction>
                <ReadMore onClick={() => setIsCollapsed(!isCollapsed)}>
                  {t(`sdk.template.main_listing.${isCollapsed ? 'readLess' : 'readMore'}`)}
                </ReadMore>
              </div>
            ) : null}
            {discoveryOfTheMoment?.landingPage && (
              <DiscoveryContainer>
                <DiscoveryItem {...discoveryOfTheMoment} onClick={() => {}} />
              </DiscoveryContainer>
            )}
          </Hat>
        </FullContent>
        <AdFullContent>
          {navigation ? <SectionNavCloud navLinks={navigation} onlyCurrent /> : null}
          <Ad className="Billboard_1" desktopOnly megaban />
        </AdFullContent>
      </Container>
      {genericItems && genericItems?.length > 0 && (
        <Featured>
          <MainContent>
            <ShowcaseListing items={genericItems.slice(0, 3)} />
          </MainContent>
          <SideContent>
            <TitleFeed>
              <HeadingTwo className="mod-huge mod-medium-black mod-without-mt">
                {t('sdk.template.main_listing.headline')}
              </HeadingTwo>
              <SwitchViewMode sectionId={section?.id} callback={(newMode: string) => setMode(newMode)} />
            </TitleFeed>
            <PostsList>
              {genericItems.slice(3, 9).map((genericItem: GenericItem, index: number) => {
                let previousDate = null;
                let currentDate = null;
                if (sectionMainFluxSortedBy === 'republishedAt') {
                  previousDate = index > 0 ? new Date(genericItems[index - 1].republishedAt) : null;
                  currentDate = new Date(genericItem.republishedAt);
                } else {
                  previousDate = index > 0 ? new Date(genericItems[index - 1].updatedAt) : null;
                  currentDate = new Date(genericItem.updatedAt);
                }
                const label = getHistoryDate(currentDate, previousDate, t('locale'));

                return (
                  <PostItemMix
                    key={index}
                    className={index === 0 && !label ? 'mod-first' : ''}
                    mode={mode}
                    separator={label}
                    {...postItemReducer({ item: genericItem, sortBy: sectionMainFluxSortedBy })}
                  />
                );
              })}
            </PostsList>
            <Cta href={`${pageInfo.section.url}2`} className="mod-blue mod-empty mod-icon mod-sm">
              {t('sdk.template.main_listing.all_news')}
              <ArrowPlainRight width={13} height={13} color="var(--theme-palette-blue)" />
            </Cta>
          </SideContent>
        </Featured>
      )}
      {insertTab ? <SelectedItemsLineTabs mainTitle={insertTab.mainTitle} tabs={insertTab.resolvedTabs} /> : null}
      {insertElements}
      {brands && brands?.length > 0 && (
        <TopBrands brands={brands} title={t('sdk.template.main_listing.brand_selection')} />
      )}
    </>
  );
};

export const MainListing: React.FC<LayoutSectionProps<MainListingSectionData>> = ({
  section,
  data,
  breadcrumb,
  pageInfo,
}) => {
  if (data?.mainFlux?.info?.overriden) {
    return <Default section={section} data={data} breadcrumb={breadcrumb} pageInfo={pageInfo} />;
  }

  return <MainListingComp section={section} data={data} breadcrumb={breadcrumb} pageInfo={pageInfo} />;
};

export default MainListing;
