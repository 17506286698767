import React from 'react';
import { GenericItem } from '@marty-js/api-sdk/types';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';
import styled, { css } from 'styled-components';
import { HeadingTwo } from '@marty-js/design/src/atoms/h2';
import { Cta } from '@marty-js/design/src/molecules/cta';
import ArrowPlainRight from '@marty-js/design/src/icons/arrowPlainRight';
import { Container, MainContent, SideContent, SideContentSticky } from '../../../../atoms/grid-container';
import { Ad } from '../../../../ads/ad';
import { ShowcaseListing } from './showcase-listing';

export type ShowcaseListingAdProps = {
  items: GenericItem[];
  title?: string;
  cta: {
    link: string;
    label: string;
  };
};

const ShowcaseWrapper = styled(Container)`
  margin: 0 0 var(--spacer-m-fluid);
`;

const Inner = styled.div`
  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      padding: 0 var(--spacer) 0 0;
      width: 100%;
    `,
  )}
`;

const CtaWrapper = styled.div`
  grid-column: 1 / 7;
  margin: var(--spacer-fluid) 0 0;
  justify-content: center;

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      > * {
        width: 100%;
      }
    `,
  )}
`;

const Title = styled.div`
  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      text-align: center;
    `,
  )}
`;

export const ShowcaseListingAd = ({ items, title, cta }: ShowcaseListingAdProps) => {
  return (
    <ShowcaseWrapper>
      <MainContent>
        <Inner>
          <Title>
            <HeadingTwo className="mod-medium-black mod-huge mod-without-mt">{title}</HeadingTwo>
          </Title>
          <ShowcaseListing items={items} />
          <CtaWrapper>
            <Cta href={cta.link} className="mod-blue mod-empty mod-icon mod-sm">
              {cta.label}
              <ArrowPlainRight width={13} height={13} color="var(--theme-palette-blue)" />
            </Cta>
          </CtaWrapper>
        </Inner>
      </MainContent>
      <SideContent>
        <SideContentSticky>
          <Ad className="HalfpageAd_1" desktopOnly />
        </SideContentSticky>
      </SideContent>
    </ShowcaseWrapper>
  );
};
