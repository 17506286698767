import React from 'react';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';
import styled, { css } from 'styled-components';
import { HeadingTwo } from '@marty-js/design/src/atoms/h2';
import { Container, FullContent } from '../../../../atoms/grid-container';
import { NavLink } from '../../../../atoms/nav-link';

export type BrandProps = {
  title: string;
  url: string;
};

export type TopBrandsProps = {
  brands: BrandProps[];
  title: string;
};

const BrandsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 0 var(--spacer-m-fluid);
`;

const BrandsItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacer-m-to-s);
  justify-content: center;

  ${mq.lte(
    DEVICE_SIZE.MEDIUM,
    css`
      flex-direction: column;
      width: 100%;
    `,
  )}
`;

const BrandItem = styled.span`
  border: solid 1px #99a4ba;
  border-radius: 10px;
  display: block;
  font-weight: 600;
  padding: var(--spacer) var(--spacer-m);
  text-align: center;
  transition: background 0.3s, color 0.3s;

  &:hover {
    color: ${(props) => (props.theme.isDark ? 'initial' : 'white')};
    background: var(--theme-color-medium);
  }
`;

export const TopBrands = ({ brands, title }: TopBrandsProps) => {
  return (
    <Container>
      <FullContent>
        <BrandsWrapper>
          <HeadingTwo className="mod-huge mod-medium-black mod-without-mt mod-discarded-x2">{title}</HeadingTwo>
          <BrandsItems>
            {brands.map((brand: BrandProps, index: number) => (
              <NavLink key={index} href={brand.url}>
                <BrandItem>{brand.title}</BrandItem>
              </NavLink>
            ))}
          </BrandsItems>
        </BrandsWrapper>
      </FullContent>
    </Container>
  );
};
