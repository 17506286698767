import React from 'react';
import { GenericItem } from '@marty-js/api-sdk/types';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';
import styled, { css } from 'styled-components';
import { HeadingTwo } from '@marty-js/design/src/atoms/h2';
import { LinkComponent, useImageComponent } from '@marty-js/design/src/utils/component';
import { displayDateDiff } from '@marty-js/design/src/atoms/date/utils';
import { useTranslation } from '@marty-js/design/src/utils/translation';
import Comment from '@marty-js/design/src/icons/comment';

export type ShowcaseOneItemProps = {
  item: GenericItem;
  title: string;
  spaceTop?: boolean;
  spaceBottom?: boolean;
};

const ListingWrapper = styled.article`
  grid-column: 1 / 10;
  margin: 0 0 var(--spacer-fluid) 0;
`;

const HeadingTitle = styled.div`
  text-align: center;

  ${mq.gte(
    DEVICE_SIZE.MEDIUM,
    css`
      margin: 0 0 var(--spacer);
    `,
  )}
`;

const Title = styled.div`
  font-family: var(--theme-typography-primary-font);
  font-weight: 700;
  font-size: 19px;
  line-height: 1.38;
  margin: var(--spacer-s) 0;

  ${mq.gte(
    DEVICE_SIZE.MEDIUM,
    css`
      font-size: 34px;
    `,
  )}
`;

const Clickable = styled.div`
  transition: color 0.3s ease;

  &:hover {
    color: var(--theme-color-primary);
  }
`;

const PublishDate = styled.span`
  color: var(--theme-colors-foreground);
`;

const Featured = styled.div`
  ${mq.lte(
    DEVICE_SIZE.MEDIUM,
    css`
      picture {
        width: 100%;
      }

      img {
        object-fit: cover;
      }
    `,
  )}
`;

const Bottom = styled.div`
  align-items: center;
  display: flex;
  font-size: 13px;
`;

const Comments = styled.div`
  align-items: center;
  display: flex;
  font-weight: bold;
  gap: 6px;
  margin: 0 0 0 auto;
`;

export const ShowcaseOneItem = ({ item, title, spaceTop, spaceBottom }: ShowcaseOneItemProps) => {
  const ImageComponent = useImageComponent();
  const t = useTranslation();

  const classes = [];
  if (spaceTop) {
    classes.push('mod-space-top');
  }

  if (spaceBottom) {
    classes.push('mod-space-bottom');
  }

  const image = {
    imageId: item.imageId,
    alt: item.title,
  };

  const imageProps = {
    ...image,
    mobileWidth: 313,
    mobileHeight: 204,
    desktopWidth: 1170,
    desktopHeight: 771,
  };

  const locale = t('locale');
  const dateTimeAgo = displayDateDiff(item.updatedAt, locale);

  return (
    <ListingWrapper className={classes.join(' ')}>
      <HeadingTitle>
        <HeadingTwo className="mod-medium mod-huge">{title}</HeadingTwo>
      </HeadingTitle>
      <Featured>
        <Clickable>
          <LinkComponent href={item.url}>
            <ImageComponent {...imageProps} />
            <Title>{item.title}</Title>
          </LinkComponent>
        </Clickable>
        <Bottom>
          <PublishDate data-testid="meta-date">{dateTimeAgo}</PublishDate>, {t('design.postItem.authorLabel')}&nbsp;
          <strong>{item.authors}</strong>
          {item.commentsNb ? (
            <Comments>
              <Comment width={24} height={24} color="var(--theme-color-primary)" />
              {item.commentsNb}
            </Comments>
          ) : null}
        </Bottom>
      </Featured>
    </ListingWrapper>
  );
};
